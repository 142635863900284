$().ready(function() {

	// hamburger
	$('#nav-expand, .close-nav').click(function(e){
		e.preventDefault();
		$('body').toggleClass('nav-expanded');
		$(this).toggleClass('icon-align-justify').toggleClass('icon-cancel');
	});

	$('.slow-scroll').on('click', function(e) {
		e.preventDefault();
		$('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 700);
	});

	if ($('.home-projects').length && $(window).width() > 767) {
		$('#project-nav').show();
		$('#project-down').on('click', function(e){
			e.preventDefault();
			if (!$(this).hasClass('disabled')) {
				$('#project-up').removeClass('disabled');
				var nextCount = parseInt($('.project-nav-count em').text()) + 1;
				if (nextCount >= $('#project-nav').data('total')) {
					$('#project-down').addClass('disabled');
				}
				$('.project-nav-count em').text(nextCount);
				var projectNav = $('#project-nav').detach();
				projectNav.appendTo('#project-' + nextCount).fadeIn();
				$('html, body').animate({scrollTop: $('#project-' + nextCount).offset().top}, 700);
			}
		});
		$('#project-up').on('click', function(e){
			e.preventDefault();
			if (!$(this).hasClass('disabled')) {
				$('#project-down').removeClass('disabled');
				var prevCount = parseInt($('.project-nav-count em').text()) - 1;
				console.log(prevCount);
				if (prevCount <= 1) {
					$('#project-up').addClass('disabled');
				}
				$('.project-nav-count em').text(prevCount);
				var projectNav = $('#project-nav').detach();
			  projectNav.appendTo('#project-' + prevCount).fadeIn();
				$('html, body').animate({scrollTop: $('#project-' + prevCount).offset().top}, 700);
			}
		});
	}

});